@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~ng-wizard/themes/ng_wizard.min.css';

/* @import "~@ng-wizard/themes/ng_wizard.css";
@import "~@ng-wizard/themes/ng_wizard.css"; */


/* @import "/node_modules/ng-wizard/themes/ng_wizard_theme_arrows.min.css"; */

tbody{
    text-align: initial;
}
.card-header .row .col-md-3,.card-header .row .col-md,.card-header .row .col,.al-center .col-7, .card-header{
    text-align: initial;
}
.ar-sidebar-icon{
    position: absolute !important;
    right: 0px;
}
.ar-sidebar-lbl{
    text-align: right;
    margin-right: 50px;
}

body {
    font-size: 14px !important;
}